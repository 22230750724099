import React, {useContext, useState} from "react";
import {LinkContainer} from "react-router-bootstrap";
import {NavDropdown} from "react-bootstrap";
import {DarkContext} from "../../providers/DarkContext";
import {useNavigate} from "react-router-dom";

function HeaderDropdown({navTitle, navTitleLink, links}) {
  let {dark} = useContext(DarkContext)
  const [show, setShow] = useState(false);
  let navigate = useNavigate();
  const showDropdown = (e) => {
    setShow(true);
  }
  const hideDropdown = (e) => {
    setShow(false);
  }

  const handleTitleLink = (e) => {
    let index = links.findIndex(l => l.title === e.target.innerText)
    let link = ""
    index === -1 ?
    navigate("/" + navTitleLink)
                 :
    navigate("/" + links[index].link)
  }

  return (
    <NavDropdown title={navTitle}
                 id="collapsible-nav-dropdown"
                 className={"navbar__dropdown " + (dark && "dark__light-font")}
                 show={show}
                 onMouseEnter={showDropdown}
                 onMouseLeave={hideDropdown}
                 onClick={(e) => handleTitleLink(e)}
    >

      {links.map((link, index) => {
        return (
          <LinkContainer to={"/" + link.link} key={index}>
            <NavDropdown.Item
              className={"navbar__link navbar__link--dropdown " + (dark && "dark__light-font dark")}
            >
              {link.title}
            </NavDropdown.Item>
          </LinkContainer>

        )
      })}


    </NavDropdown>
  );
}

export default HeaderDropdown;
