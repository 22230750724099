import React, {useState} from "react";
import PageTitle from "../components/elements/PageTitle";
import HtmlReader from "../components/blocks/HtmlReader";
import {Row, Col} from "react-bootstrap";
import MessageStrip from "../components/blocks/MessageStrip";
import {decode} from "html-entities";
import ScreenContainer from "../components/blocks/ScreenContainer";
// import DonorFormContainer from "../components/callToAction/donorForm/DonorFormContainer";
import FetchContent from "../dataManagement/FetchContent";


function SupportUs() {
  const [content, setContent] = useState(null)


  if (!content) return <FetchContent collection={"general"} docId={"supportPage"} setData={setContent} />
  return <ScreenContainer>
    <PageTitle title={"Support Us"} />
    <MessageStrip docId={"07Support"} />

    <Row>
      <Col>
        {content && content.content &&
        <>
          <HtmlReader htmlContent={content.content.body} classNames={""} />

          <Row className={"mb-4 mt-5 text-center"}>
            {content.content.buttons.map((btn, i) => {
              return <Col key={i} md={content.content.buttons.length === 1 ? 12 : 6}>
                <a target={"_blank"} href={btn.link} rel={"noreferrer"}>
                  <button type={"button"} className={"scbtn--med scbtn--2 mb-3"}
                          style={{minWidth: "24.5rem"}}>
                    {btn.title}
                  </button>
                </a>
              </Col>
            })}
          </Row>


          {content.content.additionalIsCustom ?
           <HtmlReader htmlContent={decode(content.content.additionalCustom)} classNames={""} />
                                              :
           <HtmlReader htmlContent={decode(content.content.additionalInfo)} classNames={"text-small"} />
          }


        </>}
      </Col>
      {/* DONOR-BOX*/}
      {/*<Col lg={4}>*/}
      {/*  <DonorFormContainer />*/}
      {/*</Col>*/}
    </Row>
  </ScreenContainer>
}

export default SupportUs;
